import { IAppService, IStateConfig, IFxpAppContext, IServiceEndPoints, IRouteInfo, IPartnerBundle } from '@fxp/fxpservices';
import { appSettings } from './environments/common/appsettingsGenerator';
import { ApplicationConstants, SelfServeApplicationFeatures } from './app/utils/application-constants';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class SelfServeApplication_routes implements IAppService {

    public getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
        const selfServeLandingRoute: IStateConfig = {
            name: 'SS_Landing',
            url: '/SelfServeHub',

generatedBundle: 'b57def578b36-SelfServeModule-Bundle',
            data: {
                headerName: 'Self Serve',
                breadcrumbText: 'Self Serve Hub',
                pageTitle: 'Self Serve Hub',
                partnerTelemetryName: 'FXP',
                appNameForTelemetry: `${ApplicationConstants.Application_Name}`,
                pageNameForTelemetry: 'SelfServeLanding',
                featureName: `${SelfServeApplicationFeatures.SelfServeLanding}`,
                actionName: 'ViewMyRoles'
                
            }
        };
        const manageRequestRoute: IStateConfig = {
            name: 'SS_ManageRequests',
            url: '/manageRequests',

generatedBundle: 'b57def578b36-ManageRequestsModule-Bundle',
            data: {
                headerName: 'Manage Requests',
                breadcrumbText: 'Manage Requests',
                pageTitle: 'Manage Requests',
                partnerTelemetryName: 'FXP',
                appNameForTelemetry: `${ApplicationConstants.Application_Name}`,
                pageNameForTelemetry: 'ManageMyRequests',
            }
        };
        const manageMdmRequestRoute: IStateConfig = {
            name: 'SS_ManageMdmRequests',
            url: '/manageMdmRequests',

generatedBundle: 'b57def578b36-ManageRequestsModule-Bundle',
            data: {
                headerName: 'Manage Requests',
                breadcrumbText: 'Manage Requests',
                pageTitle: 'Manage Requests',
                partnerTelemetryName: 'FXP',
                appNameForTelemetry: `${ApplicationConstants.Application_Name}`,
                pageNameForTelemetry: 'ManageMyRequests',
            }
        };
        const manageApprovalsRoute: IStateConfig = {
            name: 'SS_ManageApprovals',
            url: '/manageApprovals',

generatedBundle: 'b57def578b36-ManageApprovalsModule-Bundle',
            data: {
                headerName: 'Manage Approvals',
                breadcrumbText: 'Manage Approvals',
                pageTitle: 'Manage Approvals',
                partnerTelemetryName: 'FXP',
                appNameForTelemetry: `${ApplicationConstants.Application_Name}`,
                pageNameForTelemetry: 'ManageMyApprovals',
            }
        };

        const routeInfo: IRouteInfo = {
            applicationName: 'selfServeApplication',
            sharedBundles: [],
            routes: [selfServeLandingRoute, manageRequestRoute, manageMdmRequestRoute, manageApprovalsRoute]
        }
        return routeInfo;
    }

    public getServiceEndPoints(): Array<IServiceEndPoints> {
        return appSettings().serviceEndPoints;
    }

    public getBundles() : IPartnerBundle[] {
        const baseUrl = appSettings().cdnBaseUrl;
        let bundlesData = [
            {
                name: "SelfServe-Bundle",
                files: [
                    `${baseUrl}/vendor.bundle.js`,
                    `${baseUrl}/styles.bundle.js`,
                    `${baseUrl}/main.bundle.js`
                ],
                sequentialLoading: true
            }
        ];
        return bundlesData;
    }

 public getGeneratedBundles(){
 let currentScriptUrl = document.currentScript['src']; 
let baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/')); 
 return [
{
                name: 'b57def578b36-SelfServeModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/SelfServeModule.vendor.bundle.js',
                    baseUrl + '/SelfServeModule.bundle.js'
                ],
                sequentialLoading: true           
            },
{
                name: 'b57def578b36-ManageRequestsModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/ManageRequestsModule.vendor.bundle.js',
                    baseUrl + '/ManageRequestsModule.bundle.js'
                ],
                sequentialLoading: true           
            },
{
                name: 'b57def578b36-ManageApprovalsModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/ManageApprovalsModule.vendor.bundle.js',
                    baseUrl + '/ManageApprovalsModule.bundle.js'
                ],
                sequentialLoading: true           
            },

]
 
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(SelfServeApplication_routes, 'selfServeApplication');
