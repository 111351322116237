import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const dev: IPartnerAppSettings = {
  cdnBaseUrl: 'http://localhost:5003',
  serviceEndPoints: [
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://servicesuat.microsoft.com/fxp/ss/api/v1',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://servicesuat.microsoft.com/api/v2',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://user-onboarding-api-ppe-eus.azurewebsites.net/api/guests',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://fxpconfig-api-preprod-eus.azurewebsites.net/api/v1',
    }

  ],
  authZAPIEndpoint: 'https://servicesuat.microsoft.com/api/v2',
  selfServeAPIEndpoint: 'https://servicesuat.microsoft.com/fxp/ss/api/v1',
  uobAPIEndpoint: 'https://user-onboarding-api-ppe-eus.azurewebsites.net/api/guests',
  confitEndpoint: 'https://fxpconfig-api-preprod-eus.azurewebsites.net/api/v1/configurations'
};
