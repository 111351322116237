export class ApplicationConstants{
    static Application_Name = 'FxpPlatform.SelfServe';
    
}

export class SelfServeApplicationFeatures {
    static ManageMyRequests_ManageMyPendingRequests = `${ApplicationConstants.Application_Name}.ManageMyPendingRequests`;
    static ManageMyRequests_MyRequestHistory = `${ApplicationConstants.Application_Name}.MyRequestHistory`;
    static ManageApprovals_ManageMyPendingApprovals = `${ApplicationConstants.Application_Name}.ManageMyPendingApprovals`;
    static ManageApprovals_ManageApprovalHistory = `${ApplicationConstants.Application_Name}.ManageApprovalHistory`;
    static CreateRoleRequest = `${ApplicationConstants.Application_Name}.CreateRoleRequest`;
    static SelfServeLanding = `${ApplicationConstants.Application_Name}.SelfServeLanding`;
}

