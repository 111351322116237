import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: 'https://fxpprodcdn.azureedge.net/self-serve-ux',
  serviceEndPoints: [
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://esxp.microsoft.com/fxp/ss/api/v1',
    },
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://esxp.microsoft.com/api/v2',
    },
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://esxp.microsoft.com/uob/api/guests',
    },
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://esxp.microsoft.com/api/v1/configurations',
    }
  ],
  authZAPIEndpoint: 'https://esxp.microsoft.com/api/v2',
  selfServeAPIEndpoint: 'https://esxp.microsoft.com/fxp/ss/api/v1',
  uobAPIEndpoint: 'https://esxp.microsoft.com/uob/api/guests',
  confitEndpoint: 'https://esxp.microsoft.com/api/v1/configurations'
};
